#zmmtg-root {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%; 
  height: 100%;
  z-index: 1000;
  background-color: white;
}

/*disable the icon, logo of zoom*/
.zoom-workplace-logo,
.meeting-info-icon__icon-wrap,
.meeting-info-container__wrapper,
#app-signal.pop-panel__button-wrap .pop-panel__button,
.meeting-info-icon__meeting-paper {
  display: none;
}

.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px;
}

#overlay-button {
  position: fixed;
  top: 50%;
  right: 10px;
  z-index: 9999;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 15px;
  transform: translateY(-50%);
}

#overlay-button:hover {
  background-color: #0056b3;
}

#modal {
  position: fixed; /* Fixed position to overlay the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto; 
  max-height: 100vh; 
  background-color: rgb(254, 255, 255); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content:first baseline;
  align-items:first baseline;
  gap: 50px;
  z-index: 1100; /* Ensure it's above the Zoom SDK component */
}

#modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#attendees-button{
  position: relative; /* Fixed position to overlay the entire screen */
  top: 200;
  left: 200;
  background-color: #669fdb;
  color: white;
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  /* justify-content: center;
  align-items: center; */
  z-index: 1200; /* Ensure it's above the Zoom SDK component */
}

#attendees-button:hover{
  background-color: #3c78b8;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  background-color: rgba(255, 255, 255, 0.824);
  color: rgb(0, 0, 0);
  font-size: large;
  font-weight: bold;
}

#session-info{
  display: flex;
  width: 70vw ;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-size: 14px;
  margin-top: 30px;
  margin-right: 15%;
  flex-wrap: wrap;
  gap: 2vw;
}

#session-info span{
  font-size: large;
  font-weight: bold;
  text-align: center;
  color: rgb(10, 10, 10);
}

#session-info p{
  padding-bottom: 0px;
  margin-bottom: 0px;
}

#session-info button{
  color: white;
  background-color: rgb(124, 124, 136);
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.05s ease;
}

#session-info button:active {
  background-color: rgb(67, 67, 131); /* Change to desired color */
}

#close-session{
  background-color: red;
}

.message-container{
  display: flex;
  justify-content:flex-end;
  align-items: center;
  color: blue;
  margin-top: 0px;
}

#new-button{
  margin-top: 800px;
  background-color: black;
}

#attendees-matched-history-button{
  position: relative; /* Fixed position to overlay the entire screen */
  top: 200;
  left: 200;
  background-color: #ffffff;
  color: rgb(2, 2, 2);
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  border: #007bff solid 1px;
  margin: 10px;
  z-index: 1200;
}

.table-container {
  position: relative;
  z-index: 1300;
  width: 80%;
  height: auto;
  min-height: 60vh;
  max-height: 90vh;
  overflow-y: auto;
  background-color: rgb(201, 201, 201);
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gray-icon {
  color: gray;
}

#group-switch{
  position: relative;
  z-index: 1300;
  width: 80%;
  height: auto;
  /* min-height: 60vh;
  max-height: 90vh; */
  /* overflow-y: auto; */
  /* background-color: rgb(201, 201, 201); */
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.copy-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(59, 188, 59, 0.8); /* Semi-transparent green */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.copy-message.fade-out {
  opacity: 0;
}